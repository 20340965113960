/* ----------------------------
 *
 * Modal
 *
 * --------------------------- */

.modal-sm{
    max-width: $modal-sm !important;
}

.modal-md{
    max-width: $modal-md !important;
}

.modal-lg{
    max-width: $modal-lg !important;
}

.modal-content {
    background-color: $white;
    border: none;
    border-radius: $border-radius;
    -webkit-box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
    box-shadow: 0px 3px 3px rgba(0, 0, 0, 0.2);
}
.modal-header {
    border-bottom: 1px solid transparent;
    background-color: $modal-header-color;
    color: $white;
    text-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
}

.modal-dark {
    background-color: rgba(0, 0, 0, 0.6) !important
}
.modal-header + .modal-body {
    border-radius: 0px;
}
.modal-body {
    background-color: $white;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
}
.modal-footer {
    margin-top: 0px;
    border-top: 1px solid $border-color;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
    background-color: darken($maincontent-base-color, 1%);
}

.modal-header .btn-close {
    //margin-top: 5px;
    //background-color: #161616;
    //padding: 0px 4px;
    //opacity:  .3;
    color: #fff !important;
    text-shadow: none;
}