/* ----------------------------
 *
 * Helper Class
 *
 * --------------------------- */
/* Margin */
.ma15 { margin: 15px !important; }
.ma10 { margin: 10px !important; }
.ma5 { margin: 5px !important; }
.nm { margin: 0px !important; }
.ma-15 { margin: -15px !important; }
.ma-10 { margin: -10px !important; }
.ma-5 { margin: -5px !important; }

.mt15 { margin-top: 15px !important; }
.mt10 { margin-top: 10px !important; }
.mt5 { margin-top: 5px !important; }
.mt0 { margin-top: 0px !important; }
.mt-15 { margin-top: -15px !important; }
.mt-10 { margin-top: -10px !important; }
.mt-5 { margin-top: -5px !important; }

.mr15 { margin-right: 15px !important; }
.mr10 { margin-right: 10px !important; }
.mr5 { margin-right: 5px !important; }
.mr0 {  margin-right: 0px !important; }
.mr-15 { margin-right: -15px !important; }
.mr-10 { margin-right: -10px !important; }
.mr-5 { margin-right: -5px !important; }

.mb15 { margin-bottom: 15px !important; }
.mb10 { margin-bottom: 10px !important; }
.mb5 { margin-bottom: 5px !important; }
.mb0 { margin-bottom: 0px !important; }
.mb-15 { margin-bottom: -15px !important; }
.mb-10 { margin-bottom: -10px !important; }
.mb-5 { margin-bottom: -5px !important; }

.ml15 { margin-left: 15px !important; }
.ml10 { margin-left: 10px !important; }
.ml5 { margin-left: 5px !important; }
.ml0 { margin-left: 0px !important; }
.ml-15 { margin-left: -15px !important; }
.ml-10 { margin-left: -10px !important; }
.ml-5 { margin-left: -5px !important; }

/* Padding */
.pa15 { padding: 15px !important; }
.pa10 { padding: 10px !important; }
.pa5 { padding: 5px !important; }
.np { padding: 0px !important; }

.pt15 { padding-top: 15px !important; }
.pt10 { padding-top: 10px !important; }
.pt5 { padding-top: 5px !important; }
.pt0 { padding-top: 0px !important; }

.pr15 { padding-right: 15px !important; }
.pr10 { padding-right: 10px !important; }
.pr5 { padding-right: 5px !important; }
.pr0 { padding-right: 0px !important; }

.pb15 { padding-bottom: 15px !important; }
.pb10 { padding-bottom: 10px !important; }
.pb5 { padding-bottom: 5px !important; }
.pb0 { padding-bottom: 0px !important; }

.pl15 { padding-left: 15px !important; }
.pl10 { padding-left: 10px !important; }
.pl5 { padding-left: 5px !important; }
.pl0 { padding-left: 0px !important; }

/* Vertical align */
.valign-top { vertical-align: top !important; }
.valign-middle { vertical-align: middle !important; }
.valign-bottom { vertical-align: bottom !important; }

/* Misc */
.bradius0 { border-radius: 0px !important; }
.bdr0 { border-width: 0px !important; }
.noshadow {
    -webkit-box-shadow: none !important;
    box-shadow: none !important;
}
.dis-none {
    display: none;
}

.no-border {
    border: none;
}