/* ----------------------------
 *
 * Table
 *
 * --------------------------- */
.table > thead > tr > th,
.table > tbody > tr > th,
.table > tfoot > tr > th,
.table > thead > tr > td,
.table > tbody > tr > td,
.table > tfoot > tr > td {
    vertical-align: middle;
    border-color: $border-color;
    padding: 12px;
}
.table > tbody {
    background-color: $white;
}
.table > thead > tr > th,
.table tr > th {
    color: $dark !important;
    font-weight: 800;
    background-color: #F5F5F5;
    text-transform: uppercase;
    font-size: 11px;
}

.table > thead > tr > th,
.table tr > th a:hover {
    color: darken($dark, 10%);
}

.table > thead > tr > th {
    border-bottom: 2px solid darken($primary, 10%) !important;
}
.table-responsive > .table {
    margin-bottom: 0px;
}
/* image */
.table .media-object {
    display: inline-block;
    width: 35px;
    height: 35px;
}
.table .media-object > img {
    width: 100%;
}

/* Table email */
.table-email > tbody > tr:first-child > td {
    border-top-width: 0px;
}
/*
 * meta
 */
.table-email > tbody > tr > td.meta > .sender {
    margin: 0px;
    font-size: 13px;
    font-weight: 600;
    color: #5e5e5e;
}
.table-email > tbody > tr > td.meta > .date {
    margin: 0px;
    font-size: 12px;
    color: #aaaaaa;
}

.col-sort {
    color: $dark;

    :hover {
        color: $dark;
    }
}
.page-link {
    color: $dark;
}
.page-item.active .page-link, .page-link:hover {
    z-index: 3;
    color: $white;
    background-color: $dark;
    border-color: $dark;
}

/*
 * message
 */
.table-email > tbody > tr > td.message > .heading {
    margin: 0px;
    font-size: 13px;
    font-weight: 600;
}
.table-email > tbody > tr > td.message > .text {
    margin: 0px;
    color: #919191;
}

/* Table layout */
.table-layout {
    display: table;
    width: 100%;
    table-layout: fixed;
    margin-bottom: 20px;
}
.table-layout > [class*=" col-"],
.table-layout > [class^="col-"] {
    padding: 0px;
}
.table-layout > .col-xs-1,
.table-layout > .col-xs-2,
.table-layout > .col-xs-3,
.table-layout > .col-xs-4,
.table-layout > .col-xs-5,
.table-layout > .col-xs-6,
.table-layout > .col-xs-7,
.table-layout > .col-xs-8,
.table-layout > .col-xs-9,
.table-layout > .col-xs-10,
.table-layout > .col-xs-11 {
    display: table-cell;
    table-layout: fixed;
    float: none;
    vertical-align: middle;
}
@media (min-width: $screen-sm-min) {
    .table-layout > .col-sm-1,
    .table-layout > .col-sm-2,
    .table-layout > .col-sm-3,
    .table-layout > .col-sm-4,
    .table-layout > .col-sm-5,
    .table-layout > .col-sm-6,
    .table-layout > .col-sm-7,
    .table-layout > .col-sm-8,
    .table-layout > .col-sm-9,
    .table-layout > .col-sm-10,
    .table-layout > .col-sm-11 {
        display: table-cell;
        table-layout: fixed;
        float: none;
        vertical-align: middle;
    }
}
@media (min-width: $screen-md-min) {
    .table-layout > .col-md-1,
    .table-layout > .col-md-2,
    .table-layout > .col-md-3,
    .table-layout > .col-md-4,
    .table-layout > .col-md-5,
    .table-layout > .col-md-6,
    .table-layout > .col-md-7,
    .table-layout > .col-md-8,
    .table-layout > .col-md-9,
    .table-layout > .col-md-10,
    .table-layout > .col-md-11 {
        display: table-cell;
        table-layout: fixed;
        float: none;
        vertical-align: middle;
    }
}
@media (min-width: $screen-lg-min) {
    .table-layout > .col-lg-1,
    .table-layout > .col-lg-2,
    .table-layout > .col-lg-3,
    .table-layout > .col-lg-4,
    .table-layout > .col-lg-5,
    .table-layout > .col-lg-6,
    .table-layout > .col-lg-7,
    .table-layout > .col-lg-8,
    .table-layout > .col-lg-9,
    .table-layout > .col-lg-10,
    .table-layout > .col-lg-11 {
        display: table-cell;
        table-layout: fixed;
        float: none;
        vertical-align: middle;
    }
}

/* Table contextual */
/*
 * hover
 */
.table-hover > tbody > tr:hover > td,
.table-hover > tbody > tr:hover > th {
    background-color: lighten($gray, 4%);
}
/*
 * striped
 */
.table-striped > tbody > tr:nth-child(odd) > td,
.table-striped > tbody > tr.odd > td,
.table-striped > tbody > tr:nth-child(odd) > th,
.table-striped > tbody > tr.odd > th {
    background-color: lighten($gray, 5%);
}
/*
 * stroke
 */
.table > thead > tr > td.stroke,
.table > tbody > tr > td.stroke,
.table > tfoot > tr > td.stroke,
.table > thead > tr > th.stroke,
.table > tbody > tr > th.stroke,
.table > tfoot > tr > th.stroke,
.table > thead > tr.stroke >td,
.table > tbody > tr.stroke > td,
.table > tfoot > tr.stroke > td,
.table > thead > tr.stroke > th,
.table > tbody > tr.stroke > th,
.table > tfoot > tr.stroke > th {
    background-color: $default;
    color: #ccc;
    text-shadow: 0px 1px 0px rgba(255, 255, 255, 0.8);
    text-decoration: line-through;
}
.table-hover > tbody > tr.stroke:hover > td {
    background-color: #f5f5f5;
}
/*
 * active
 */
.table > thead > tr > td.active,
.table > tbody > tr > td.active,
.table > tfoot > tr > td.active,
.table > thead > tr > th.active,
.table > tbody > tr > th.active,
.table > tfoot > tr > th.active,
.table > thead > tr.active >td,
.table > tbody > tr.active > td,
.table > tfoot > tr.active > td,
.table > thead > tr.active > th,
.table > tbody > tr.active > th,
.table > tfoot > tr.active > th,
.table-hover > tbody > tr.active:hover > td,
.table-hover > tbody > tr >.active:hover,
.table-hover > tbody >.active:hover > td,
.table-hover > tbody >.active:hover > th {
    background-color: $default !important;
    border-color: $dark;
}
/*
 * info
 */
.table > thead > tr > td.info,
.table > tbody > tr > td.info,
.table > tfoot > tr > td.info,
.table > thead > tr > th.info,
.table > tbody > tr > th.info,
.table > tfoot > tr > th.info,
.table > thead > tr.info >td,
.table > tbody > tr.info > td,
.table > tfoot > tr.info > td,
.table > thead > tr.info > th,
.table > tbody > tr.info > th,
.table > tfoot > tr.info > th,
.table-hover > tbody > tr.info:hover > td,
.table-hover > tbody > tr >.info:hover,
.table-hover > tbody >.info:hover > td,
.table-hover > tbody >.info:hover > th {
    background-color: lighten($info, 30%) !important;
    border-color: lighten($info, 25%);
}

.table > thead > tr > td.primary,
.table > tbody > tr > td.primary,
.table > tfoot > tr > td.primary,
.table > thead > tr > th.primary,
.table > tbody > tr > th.primary,
.table > tfoot > tr > th.primary,
.table > thead > tr.primary > td,
.table > tbody > tr.primary > td,
.table > tfoot > tr.primary > td,
.table > thead > tr.primary > th,
.table > tbody > tr.primary > th,
.table > tfoot > tr.primary > th {
    background-color: $dark;
    color: #fff
}

.table-hover > tbody > tr > td.primary:hover,
.table-hover > tbody > tr > th.primary:hover,
.table-hover > tbody > tr.primary:hover > td,
.table-hover > tbody > tr:hover > .primary,
.table-hover > tbody > tr.primary:hover > th {
    background-color: $dark;
    color: #fff
}

/*
 * warning
 */
.table > thead > tr > td.warning,
.table > tbody > tr > td.warning,
.table > tfoot > tr > td.warning,
.table > thead > tr > th.warning,
.table > tbody > tr > th.warning,
.table > tfoot > tr > th.warning,
.table > thead > tr.warning >td,
.table > tbody > tr.warning > td,
.table > tfoot > tr.warning > td,
.table > thead > tr.warning > th,
.table > tbody > tr.warning > th,
.table > tfoot > tr.warning > th,
.table-hover > tbody > tr.warning:hover > td,
.table-hover > tbody > tr >.warning:hover,
.table-hover > tbody >.warning:hover > td,
.table-hover > tbody >.warning:hover > th {
    background-color: lighten($warning, 25%) !important;
    border-color: lighten($warning, 21%);
}
/*
 * success
 */
.table > thead > tr > td.success,
.table > tbody > tr > td.success,
.table > tfoot > tr > td.success,
.table > thead > tr > th.success,
.table > tbody > tr > th.success,
.table > tfoot > tr > th.success,
.table > thead > tr.success >td,
.table > tbody > tr.success > td,
.table > tfoot > tr.success > td,
.table > thead > tr.success > th,
.table > tbody > tr.success > th,
.table > tfoot > tr.success > th,
.table-hover > tbody > tr.success:hover > td,
.table-hover > tbody > tr >.success:hover,
.table-hover > tbody >.success:hover > td,
.table-hover > tbody >.success:hover > th {
    background-color: lighten($success, 32%) !important;
    border-color: lighten($success, 26%);
}
/*
 * danger
 */
.table > thead > tr > td.danger,
.table > tbody > tr > td.danger,
.table > tfoot > tr > td.danger,
.table > thead > tr > th.danger,
.table > tbody > tr > th.danger,
.table > tfoot > tr > th.danger,
.table > thead > tr.danger >td,
.table > tbody > tr.danger > td,
.table > tfoot > tr.danger > td,
.table > thead > tr.danger > th,
.table > tbody > tr.danger > th,
.table > tfoot > tr.danger > th,
.table-hover > tbody > tr.danger:hover > td,
.table-hover > tbody > tr >.danger:hover,
.table-hover > tbody >.danger:hover > td,
.table-hover > tbody >.danger:hover > th {
    background-color: lighten($danger, 30%) !important;
    border-color: lighten($danger, 26%);
}

/*
 * teal
 */
.table > thead > tr > td.teal,
.table > tbody > tr > td.teal,
.table > tfoot > tr > td.teal,
.table > thead > tr > th.teal,
.table > tbody > tr > th.teal,
.table > tfoot > tr > th.teal,
.table > thead > tr.teal >td,
.table > tbody > tr.teal > td,
.table > tfoot > tr.teal > td,
.table > thead > tr.teal > th,
.table > tbody > tr.teal > th,
.table > tfoot > tr.teal > th,
.table-hover > tbody > tr.teal:hover > td,
.table-hover > tbody > tr >.teal:hover,
.table-hover > tbody >.teal:hover > td,
.table-hover > tbody >.teal:hover > th {
    background-color: lighten($teal, 30%) !important;
    border-color: lighten($teal, 26%);
}


/* Table td toolbar */
.table td .toolbar {
    display: inline-block;
    vertical-align: middle;
    line-height: normal;
}
.table td .toolbar .btn.btn-link {
    border: none;
    padding: 0px 5px;
}
.table td .toolbar.toolbar-hover {
    display: none;
}
.table tr:hover .toolbar.toolbar-hover {
    display: inline-block;
}
/* Media query
 -------------------------------*/
@media (max-width: 768px) {
    .table td .toolbar {
        min-width: 80px;
    }
    .table-responsive {
        width: 100%;
        overflow-x: scroll;
        overflow-y: hidden;
        border: 0px;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .panel .table-responsive {
        border-left: 0px;
        border-right: 0px;
        border-top: 0px;
    }
}

.pl-table {
    .row{
        margin: 5px 0;
        padding: 0 20px;
        align-items: center;
    }
    .col{
        padding: 0 10px;
        overflow: hidden;

    }
    .pl-thead {
        color: #979797;
        font-weight: bold;
        text-transform: uppercase;
        font-size: 11px;
        margin: 15px 0;
        &.tall {
            background-color: $dark;
            margin: 0;
            padding: 5px 0;
            color: rgba(255, 255, 255, 0.7);
            text-transform: initial;
            .col {
                border-right: 1px solid rgba(255, 255, 255, 0.2);
                text-align: center;
                align-self: stretch;
                &:last-child {
                    border: none;
                }
                .large {
                    font-size: 21px;
                    color: #ffffff;
                }
            }
        }
    }
    .pl-tbody {
        .row {
            background-color: #ffffff;
            padding-top: 7px;
            padding-bottom: 7px;
            color: #979797;
            font-size: 11px;
        }
        &.scroll {
            max-height: 100px;
            overflow-x: scroll;
        }
    }
}
