@import "base";

/*BEGIN UI*/
@import "scss/ui/helper";
@import "scss/ui/breadcrumb";
@import "scss/ui/form";
@import "scss/ui/button";
@import "scss/ui/dropdown";
@import "scss/ui/labelbadge";
@import "scss/ui/typography";
@import "scss/ui/icon";
@import "scss/ui/alert";
@import "scss/ui/table";
@import "scss/ui/cart";
@import "scss/ui/totop";
@import "scss/ui/modal";
/*END UI*/


/* Plugins etc */
@import "~ol/ol.css";
@import "~rrssb/css/rrssb.css";
@import "~chart.js/dist/Chart.css";
@import "~humane-js/themes/flatty.css";
@import "~lightbox2/src/css/lightbox.css";
@import "~bs-stepper/dist/css/bs-stepper.css";
@import "~datetimepicker/dist/DateTimePicker.min.css";
@import "~datatables.net-bs5/css/dataTables.bootstrap5.css";
@import "~datatables.net-responsive-bs5/css/responsive.bootstrap5.css";
@import "~datatables.net-fixedheader-bs5/css/fixedHeader.bootstrap5.css";
@import "~summernote/dist/summernote-lite.css";
@import "~fullcalendar/main.css";

@import "../../../public/vendor/cookie-consent/css/cookie-consent.css";

/* Our public styles */
@import "public";




