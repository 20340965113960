html, body {
  height: 100%;
}

body {
  font-family: sans-serif;
  background: url('/images/background.jpg') no-repeat center center fixed;
  background-size: cover;
}

table {
  margin: 0;
}

.pointer {
  cursor: pointer;
}

.h-300 {
  min-height: 300px;
}

label.required::after {
  content: '*';
  color: red;
  padding-left: 3px;
  font-size: 9px;
}

section.container {
  //min-width: 80%;
  //background-color: #ffffff;
  margin-bottom: 25px;
}

.section_head {
  border: none !important;
  font-size: 35px;
  text-align: center;
  margin: 0;
  margin-bottom: 30px;
  //letter-spacing: 2em;
  font-weight: 200;
}

.section_head h1 {
  margin: 0;
  font-weight: 100;
  text-align: center;
}

section {
  color: #666;
}

section .content {
  /*padding: 40px 0 40px 0;*/
}

section h1 {}

.embedded  {
  /*padding: 10px;*/
  /*background-color: #f8f8f8;*/
  padding-left: 5px;
  padding-right: 5px;
}

.order_header {
  text-align: center
}

.order_header .massive-icon {
  display: block;
  width: 120px;
  height: 120px;
  font-size: 100px;
  margin: 0 auto;
  color: #63C05E;
}

.order_header h1 {
  margin-top: 20px;
  text-transform: uppercase;
}

.order_header h2 {
  margin-top: 5px;
  font-size: 20px;
}

.ticket_download_link {
  border-bottom: 3px solid;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
}

.d-block {
  display: block !important;
}

// OVT - Overtime
.table {
  --bs-table-bg: transparent!important; // FIX   --bs-table-bg:
}

.ovt:nth-child(odd) {
  background-color: #B5C8FA !important;
}

.ovt:nth-child(even) {
  background-color: #91ADF8 !important;
}

// DNF - Do not Finished
.dnf:nth-child(odd) {
  background-color: #F07700 !important;
  color: #E9E9E9 !important
}

.dnf:nth-child(even) {
  background-color: #C46100 !important;
  color: #E9E9E9 !important
}

// DSQ - Disqualified
.dsq:nth-child(odd) {
  background-color: #CC2400 !important;
  color: #E9E9E9 !important;
}

.dsq:nth-child(even) {
  background-color: #F02A00 !important;
  color: #E9E9E9 !important;
}

// DNS - Do Not Started
.dns:nth-child(odd) {
  background-color: #AFAFAF !important;
}

.dns:nth-child(even) {
  background-color: #DCDCDC !important;
}

// FIN - Finished
.fin:nth-child(odd) td {
  background-color: #d7ffc8;
}

.fin:nth-child(even) td {
  background-color: #ecffe5;
}

.legend { list-style: none; padding-left: 20px !important; }
.legend li { float: left; margin-right: 10px; }
.legend span { border: 1px solid #ccc; float: left; width: 25px; height: 15px; margin: 2px; }

.legend .c-n { background-color: #313695; }
.legend .c-15 { background-color: #4575B4; }
.legend .c-10 { background-color: #74ADD1; }
.legend .c-5 { background-color: #ABD9E9; }
.legend .c0 { background-color: #E0F3F8; }
.legend .c5 { background-color: #FEE090; }
.legend .c10 { background-color: #FDAE61; }
.legend .c15 { background-color: #F46D43; }
.legend .c20 { background-color: #D73027; }
.legend .cn { background-color: #A50026; }

#breadcrumb-results {
  margin-top: -50px;
  display: flex;
  position: absolute;
  border-radius: 2px;
  padding: 15px;
}

#breadcrumb-results .breadcrumb-item + .breadcrumb-item::before {
  content: "›";
}

#organiser_page_wrap {
  #intro {
    position: relative;
    text-align: center;
    font-weight: 100;
    padding: 20px 0 20px 0;
    border: none;
    margin-bottom: 0;
    margin-top: 20px;
    color: #fff;
    background-color: #AF5050;
  }

  .organiser_logo {
    margin: 0 auto;
  }

  .organiser_logo .thumbnail {
    background-color: transparent;
    border: none;
  }
}

#goLiveBar {
  background-color: rgba(255, 255, 255, .9);
  text-align: center;
  padding: 10px;
}

.adminLink, .adminLink:hover, .adminLink:visited, .adminLink:active {
  color: #fff;
}

#results {
  margin-top: 50px;
  background: #fff;
  border-top: 3px solid #444444;
  padding: 10px !important;
}

#races-tab {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
  background: rgba(0,0,0,0.4);
}

#races-tab ul {
  min-height: 100px;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
}

#races-tab li {
  display:inline-block;
  float: left;
  margin-right: 1px;
}

#races-tab ul li p {
  color: rgba(255, 255, 255, 0.8) !important;
  margin: 0;
  display: block;
  //width: 100%;
  //height: 100%;
}

#races-tab ul li {
  display: flex;
  text-align: center;
  flex-flow: column nowrap;
  flex-basis: 0;
  flex-grow: 2;
  margin-right: 2px;
  padding: 5px;
  background: rgba(0, 0, 0, .4);
  justify-content: center;
  cursor: pointer;
  font-size: 1.5em;
  font-weight: 700;
  min-height: 65px
}

#races-tab ul li:hover {
  background: rgba(233, 232, 232, 0.4);
}

#races-tab ul li:hover p {
  color: #fff !important;
}

#races-tab.fix ul li.sel {
  order: -1
}

#races-tab ul li.sel p {
  color: #666 !important;
}

#races-tab ul li.sel {
  background-color: #e7e7e7
}

#races-tab ul li.logo{
  background-color: #fff !important;
}

#races-tab ul li.logo img{
  max-width: 110px;
  margin: 0 auto;
  padding: 0;
  line-height: 1.6
}

/*Style 'show menu' label button and hide it by default*/
#races-tab .show-menu {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  text-decoration: none;
  color: #fff;
  background: #19c589;
  text-align: center;
  padding: 10px 0;
  display: none;
}

/*Hide checkbox*/
#races-tab input[type=checkbox]{
  display: none;
  -webkit-appearance: none;
}

/*Show menu when invisible checkbox is checked*/
#races-tab input[type=checkbox]:checked ~ #races-tab-menu{
  display: block;
}

#event_page_wrap {
  min-height: 100%;
  margin: 0 auto -60px; /* the bottom margin is the negative value of the footer's height */
  background: rgba(0, 0, 0, .4);

  #organiserHead {
    text-align: center;
    color: #fff;
    border: none;
    font-size: 15px;
    opacity: .6;
    transition: all 0.15s ease-in-out;
    background: rgba(0, 0, 0, .4);
    line-height: 30px;
    cursor: pointer;
  }

  #organiserHead:hover {
    opacity: 1;
  }

  #navigation-bar {
    position: relative;
    text-align: center;
    font-weight: 100;
    padding: 0 0 10px 0;
    color: #ffffff;
    border: none;
    background-color: transparent;
    margin-bottom: 0;
    min-width: 100%;
  }

  .bg-transperent {
    background-color: rgba(0,0,0,0.6) !important;
    transition: all 0.15s ease-in-out;
    color: #fff;
  }

  #intro {
    position: relative;
    text-align: center;
    font-weight: 100;
    padding: 20px 0 20px 0;
    color: #ffffff;
    border: none;
    background-color: transparent;
    margin-bottom: 0;
  }

  #intro h1 {
    /*display: inline-block;*/
    position: relative;
    padding: 10px 10px;
    /*background: rgba(0,0,0,0.4);*/
    margin: 0;
    font-weight: 400;
    font-size: 60px;
    margin-bottom: 10px;
  }

  #intro .event_date {
    font-size: 15px;
    padding: 10px;
    font-weight: 500;
  }

  #intro .event_venue {
    font-size: 19px;
  }

  #intro .event_buttons {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  #intro .event_buttons .btn-event-link {
    line-height: 35px;
    font-size: 17px;
    text-transform: uppercase;
    letter-spacing: 5px;
    border: 1px solid;
    border-color: rgba(255, 255, 255, 0.2);
    text-decoration: none;
    color: #fff;
    padding: 0 15px;
    transition: all 0.15s ease-in-out;
    width: 100%;
    background: rgba(0, 0, 0, 0.3);

  }
  #intro .event_buttons .btn-event-link:hover {
    border-color: rgba(255, 255, 255, 0.6);

  }

  #tickets .input-group-addon {
    background: none;
    border: none;
  }

  #tickets table tr:first-child td {
    border-top: none;
  }

  #tickets table tr td {
    padding: 20px 0px;
  }

  #details .event_poster img {
    border: 4px solid #f6f6f6;
    max-width: 100%;
    min-width: 100%;
  }

  /*Resets for user provided content */

  #details .event_details iframe,
  #details .event_details img { /* Youtube embed fix */
    max-width: 100%;
  }

  #details .event_details h1,
  #details .event_details h2,
  #details .event_details h3,
  #details .event_details h4,
  #details .event_details h5,
  #details .event_details h6 {
    margin-top: 0px;
    margin-bottom: 15px;
    font-weight: 100;
  }
  #details .event_details h1 {
    font-size: 28px;
  }
  #details .event_details h2 {
    font-size: 24px;
  }
  #details .event_details h3 {
    font-size: 20px;
  }
  #details .event_details h4 {
    font-size: 17px;
  }

  #share .btn {
    margin-bottom: 20px;
  }

  #location {
    /*height: 500px;*/
    padding: 0px;

  }

  #location .google-maps {
    position: relative;
    /*padding-bottom: 75%; // This is the aspect ratio*/
    overflow: hidden;
  }
  #location .google-maps iframe {
    width: 100% !important;
    height: 100% !important;
    min-height: 500px;
  }

}

footer, .push {
  height: 60px; /* '.push' must be the same height as 'footer' */
}

#footer {
  background-color: #888;
  background-color: rgba(0, 0, 0, .4);
  min-height: 60px;
  line-height: 60px;
  color: #fff;
  text-align: center;
}

#organiser {
  text-align: center;

  .contact_form {
    display: none;
    padding: 20px;
    margin-top: 25px;
    text-align: left;
  }

}

.totop {
  border-radius: 0;
  background-color: #888;
  background-color: rgba(0, 0, 0, .4);
}

.totop:hover {
  background-color: #fff;
  background-color: rgba(255, 255, 255, .4);
  color: #000;
}

.powered_by_embedded  {
  text-align: center;
  padding: 4px;

  a {
    color: #333 !important;
  }
}

/* ==========================================================================
Small devices (tablets, 768px and up)
========================================================================== */

/*Responsive Styles*/
@media screen and (max-width : 760px){
  /*Make dropdown links appear inline*/

  #races-tab {
    display: block !important;
  }

  #races-tab ul {
    position: static;
    display: none;
  }
  /*Create vertical spacing*/
  #races-tab li {
    margin-bottom: 1px;
  }
  /*Make all menu links full width*/
  #races-tab ul li, #races-tab li p {
    width: 100%;
  }
  /*Display 'show menu' link*/
  #races-tab .show-menu {
    display:block;
  }
  .container-md {
    width: 100% !important;
    padding: 0;
  }
  .content {
    padding: 0 !important;
  }
}

@media (min-width: 100px) and (max-width: 767px) {

  .row {
    margin: 0;
  }

  section.container {
    margin-bottom: 0;
  }

  .section_head {
    padding: 10px;
    font-size: 30px;
  }

  .main_content {
    padding: 0px;
    background: #fff; /*make gutters white for tablet/mobile*/
  }

  #organiser_page_wrap {

    #intro {
      padding: 30px;
      margin-top: 0;
    }
    #intro h1 {
      font-size: 2.236em;
      padding: 15px;
    }
    #events {
      min-height: 350px;
    }
  }

  #event_page_wrap {

    #intro {
      padding: 30px;
    }

    #intro .event_date h2 {
      font-size: 20px;
    }

    #intro .event_date h4 {
      font-size: 11px;
    }

    #intro h1 {
      font-size: 2.236em;
      padding: 15px;
    }

    #intro .event_venue {
      color: #fff;
      font-size: 20px;
      margin-top: 10px;
    }

    #intro .event_buttons {
      margin-top: 50px;
    }

    #intro .event_buttons .btn-event-link {
      padding: 5px 0px;
      font-size: 18px;
      margin-bottom: 5px;
      line-height: 30px;
    }

    #tickets .btn-checkout {
      width: 100%;
    }

    #location .google-maps iframe {
      min-height: 290px;
    }

    #events_wrapper .col-sm-12 {
      padding-left: 0!important;
    }
  }
}

/* ==========================================================================
   Medium devices (desktops, 992px and up)
   ========================================================================== */

@media (min-width: 992px) {
  /*#intro h1  { font-size: 50px; }*/
}

.rrssb-buttons.large-format li a {
  border-radius: 0;
}

/*
-----------------------------------------------------
Event Listing / Organiser Page
-----------------------------------------------------
*/
.event-listing-heading {
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 20px;
}

.event-list {
  list-style: none;
  margin: 0px;
  padding: 0px;
}

.event-list > li {
  background-color: #F3F3F3;
  padding: 0px;
  margin: 0px 0px 20px;
}

.event-list > li > time {
  display: inline-block;
  width: 100%;
  padding: 5px;
  text-align: center;
  text-transform: uppercase;
}

.event-list > li > time > span {
  display: none;
}

.event-list > li > time > .day {
  display: block;
  font-size: 18pt;
  font-weight: 100;
  line-height: 1;
}

.event-list > li time > .month {
  display: block;
  font-size: 24pt;
  font-weight: 900;
  line-height: 1;
}

.event-list > li > img {
  width: 100%;
}

.event-list > li > .info {
  padding-top: 10px;
  text-align: center;
}

.event-list > li > .info > .title {
  font-size: 15pt;
  font-weight: 500;
  margin: 0px;
}

.event-list > li > .info > .desc {
  font-size: 10pt;
  font-weight: 300;
  margin: 0px;
}

.event-list > li > .info > ul {
  display: table;
  list-style: none;
  margin: 10px 0px 0px;
  padding: 0px;
  width: 100%;
  text-align: center;
  background-color: #DEDEDE;
}

.event-list > li > .info > ul > li {
  display: table-cell;
  cursor: pointer;
  color: rgb(30, 30, 30);
  font-size: 11pt;
  font-weight: 300;
  padding: 3px 0px;
}

.event-list > li > .info > ul > li > a {
  display: block;
  width: 100%;
  color: #6D6D6D;
  text-decoration: none;
}

.event-list > li > .info > ul > li:hover {
  color: rgb(30, 30, 30);
  background-color: rgb(200, 200, 200);
}

@media (min-width: 768px) {

  .event-list > li {
    position: relative;
    display: block;
    width: 100%;
    height: 120px;
    padding: 0px;
  }

  .event-list > li > time,
  .event-list > li > img {
    display: inline-block;
  }

  .event-list > li > time,
  .event-list > li > img {
    width: 120px;
    float: left;
  }

  .event-list > li > .info {
    background-color: rgb(245, 245, 245);
    overflow: hidden;
  }

  .event-list > li > time,
  .event-list > li > img {
    width: 120px;
    height: 120px;
    padding: 0px;
    margin: 0px;
  }

  .event-list > li > time > .day {
    font-size: 56pt;
  }

  .event-list > li > .info {
    position: relative;
    height: 120px;
    text-align: left;
    padding-right: 40px;
    padding-top: 30px;
  }

  .event-list > li > .info > .title,
  .event-list > li > .info > .desc {
    padding: 0px 10px;
  }

  .event-list > li > .info > ul {
    position: absolute;
    left: 0px;
    bottom: 0px;
    background-color: #D2D2D2;
  }

}
.dtpicker-overlay {
  z-index: 9999;
}

.dtpicker-close {
  display: none;
}

.dtpicker-header .dtpicker-title {
  color: #AFAFAF;
  text-align: center;
  font-size: 18px;
  font-weight: normal;
}

.dtpicker-header .dtpicker-value {
  padding: .8em .2em .2em 2em;
  color: $primary;
  text-align: center;
  font-size: 1.4em;
}

/* Datetime picket */
.dtpicker-buttonCont .dtpicker-buttonClear {
  background: $danger;
  border-radius: $border-radius;
}

.dtpicker-buttonCont .dtpicker-buttonSet {
  background: $success;
  border-radius: $border-radius;
}

.dtpicker-content {
  border-radius: $border-radius;
}

table.results-table tr:hover td, table.top-table tr:hover td, table.organiser-table tr:hover td{
  background-color: #ffd25b !important;
  color: #000 !important;
}

table.organiser-table {
  cursor: pointer;
}
//

table.modal-splits-table > tbody > tr > td {
  padding: 2px!important;
}

table.top-table{
  width: 99%;
  display:table;
}
table.top-table  thead th {
  background-color: #F5F5F5;
  color: #000;
  text-transform: none;
  font-weight: bold;
  font-size: 15px;
}
table.top-table thead th {
  font-size: 10px;
  font-weight: bold;
}
table.top-table tbody td {
  font-size: 13px;
}
table.top-table tr:nth-child(even) {
  background: #e9e8e8;
}

.card{ background-color: #fff; border: 1px solid transparent; border-radius: 6px; }
.card > .card-link{ color: #333; }
.card > .card-link:hover{  text-decoration: none; }
.card > .card-link .card-img img{ border-radius: 6px 6px 0 0; }
.card .card-img{ position: relative; padding: 0; display: table; }
.card .card-img .card-caption{
  position: absolute;
  right: 0;
  bottom: 16px;
  left: 0;
}
//.card .card-body{ display: table; width: 100%; padding: 12px; }
.card .card-header{ border-radius: 6px 6px 0 0; padding: 8px; }
.card .card-footer{ border-radius: 0 0 6px 6px; padding: 8px; }
.card .card-left{ position: relative; float: left; padding: 0 0 8px 0; }
.card .card-right{ position: relative; float: left; padding: 8px 0 0 0; }
.card .card-body h1:first-child,
.card .card-body h2:first-child,
.card .card-body h3:first-child,
.card .card-body h4:first-child,
.card .card-body .h1,
.card .card-body .h2,
.card .card-body .h3,
.card .card-body .h4{ margin-top: 0; }
.card .card-body .heading{ display: block;  }
.card .card-body .heading:last-child{ margin-bottom: 0; }

.card .card-body .lead{ text-align: center; }
.card.card-results {min-height: 520px !important;}
.card.card-results > .card-body {min-height: 350px!important}

@media( min-width: 768px ){
  .card .card-left{ float: left; padding: 0 8px 0 0; }
  .card .card-right{ float: left; padding: 0 0 0 8px; }

  .card .card-4-8 .card-left{ width: 33.33333333%; }
  .card .card-4-8 .card-right{ width: 66.66666667%; }

  .card .card-5-7 .card-left{ width: 41.66666667%; }
  .card .card-5-7 .card-right{ width: 58.33333333%; }

  .card .card-6-6 .card-left{ width: 50%; }
  .card .card-6-6 .card-right{ width: 50%; }

  .card .card-7-5 .card-left{ width: 58.33333333%; }
  .card .card-7-5 .card-right{ width: 41.66666667%; }

  .card .card-8-4 .card-left{ width: 66.66666667%; }
  .card .card-8-4 .card-right{ width: 33.33333333%; }
}

/* -- default theme ------ */
.card-default{
  border-color: #ddd;
  background-color: #fff;
  margin-bottom: 24px;
}
.card-default > .card-header,
.card-default > .card-footer{ color: #333; background-color: #ddd; }
.card-default > .card-header{ border-bottom: 1px solid #ddd; padding: 8px; }
.card-default > .card-footer{ border-top: 1px solid #ddd; padding: 8px; }
.card-default > .card-body{  }
.card-default > .card-img:first-child img{ border-radius: 6px 6px 0 0; }
.card-default > .card-left{ padding-right: 4px; }
.card-default > .card-right{ padding-left: 4px; }
.card-default p:last-child{ margin-bottom: 0; }
.card-default .card-caption { color: #fff; text-align: center; text-transform: uppercase; }


/* -- price theme ------ */
.card-price{ border-color: #999; background-color: #ededed; margin-bottom: 24px; }
.card-price > .card-heading,
.card-price > .card-footer{ color: #333; background-color: #fdfdfd; }
.card-price > .card-heading{ border-bottom: 1px solid #ddd; padding: 8px; }
.card-price > .card-footer{ border-top: 1px solid #ddd; padding: 8px; }
.card-price > .card-img:first-child img{ border-radius: 6px 6px 0 0; }
.card-price > .card-left{ padding-right: 4px; }
.card-price > .card-right{ padding-left: 4px; }
.card-price .card-caption { color: #fff; text-align: center; text-transform: uppercase; }
.card-price p:last-child{ margin-bottom: 0; }

.card-price .price{
  text-align: center;
  color: #337ab7;
  font-size: 3em;
  text-transform: uppercase;
  line-height: 0.7em;
  margin: 24px 0 16px;
}
.card-price .price small{ font-size: 0.4em; color: #66a5da; }
.card-price .details{ list-style: none; margin-bottom: 24px; padding: 0 18px; }
.card-price .details li{ text-align: center; margin-bottom: 8px; }
.card-price .buy-now{ text-transform: uppercase; }
.card-price table .price{ font-size: 1.2em; font-weight: 700; text-align: left; }
.card-price table .note{ color: #666; font-size: 0.8em; }

.columns-2 {
  @include media-breakpoint-up(sm) {
    column-count: 1;
  }
  @include media-breakpoint-up(lg) {
    column-count: 2;
  }
}

.columns-1 {
    column-count: 1;
}

input[readonly] {
  background-color: transparent !important;
  cursor: pointer !important;
}

.notifyjs-corner {
  z-index: 2147483647 !important;
}

.bs-stepper .step-trigger {
  font-size: 0.7rem !important;
}

.chart {
  margin-top: 50px !important;
  width: 97%;
  position: relative;
  margin: auto;
}

.checkpoint-line{
  width: 1px;
  height: 100%;
  position:absolute;
  filter:alpha(opacity=30);
  background-color: #000000;
}

.checkpoint-line:nth-of-type(even) {
  margin-top: 2em;
  height: calc(100% - 2em);
}

.checkpoint-info {
  z-index:400;
  width: 80px;
  height: 30px;
  position:absolute;
  left:-40px;
  opacity:1.0;
  filter:alpha(opacity=100);
  font-family: Verdana, Arial, Helvetica, sans-serif;
  font-size: 11px;
  text-align: center;
  color: #302D31;
  top: -6em;
}

.checkpoint-info-block{
  display: block;
}

.checkpoint-info-responsive {
  display: none;
}

.fc-toolbar {
  .fc-center h2 {
    font-size: 1.25em
  }
  display: block !important;
  @media (min-width: 600px) {
    display: flex !important;
  }
}

.map {
  width: 100%;
  height:400px;
}

/* magnifying glass icon */
.zoom:after {
  content: '';
  display: block;
  width: 33px;
  height: 33px;
  position: absolute;
  top: 0;
  right: 15px;
  background: url(/images/search.png);
}

.lb-dataContainer {
  position: absolute;
  top:-50px; left: 50%;
  transform: translateX(-50%);
}

@media(max-width:768px){
  .checkpoint-info {
    bottom: 110%;
  }
  .checkpoint-info-responsive {
    display: block;
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -ms-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
  }
  .checkpoint-info-block{
    display: none;
  }
}

[data-bs-toggle="collapse"].collapsed .if-not-collapsed {
  display: none;
}
[data-bs-toggle="collapse"]:not(.collapsed) .if-collapsed {
  display: none;
}


.timeline-1 {
  border-left: 3px solid #b565a7;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background: rgba(177, 99, 163, 0.09);
  margin: 0 auto;
  position: relative;
  padding: 50px;
  list-style: none;
  text-align: left;
  max-width: 40%;
}

@media (max-width: 767px) {
  .timeline-1 {
    max-width: 98%;
    padding: 25px;
  }
}

.timeline-1 .event {
  border-bottom: 1px dashed #000;
  padding-bottom: 25px;
  margin-bottom: 25px;
  position: relative;
}

@media (max-width: 767px) {
  .timeline-1 .event {
    padding-top: 30px;
  }
}

.timeline-1 .event:last-of-type {
  padding-bottom: 0;
  margin-bottom: 0;
  border: none;
}

.timeline-1 .event:before,
.timeline-1 .event:after {
  position: absolute;
  display: block;
  top: 0;
}

.timeline-1 .event:before {
  left: -207px;
  content: attr(data-date);
  text-align: right;
  font-weight: 100;
  font-size: 0.9em;
  min-width: 120px;
}

@media (max-width: 767px) {
  .timeline-1 .event:before {
    left: 0px;
    text-align: left;
  }
}

.timeline-1 .event:after {
  -webkit-box-shadow: 0 0 0 3px #b565a7;
  box-shadow: 0 0 0 3px #b565a7;
  left: -55.8px;
  background: #fff;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  content: "";
  top: 5px;
}

@media (max-width: 767px) {
  .timeline-1 .event:after {
    left: -31.8px;
  }
}

ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: " ";
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  height: 100%;
  z-index: 400;
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 20px;
}
ul.timeline > li:before {
  content: " ";
  background: white;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #22c0e8;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 400;
}
