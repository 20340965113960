/* ----------------------------
 *
 * Label & Badge & Icons
 *
 * --------------------------- */
.label {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    display: inline-block;
    font-size: 11px;
    font-weight: bold;
    line-height: 1.7;
    height: 18px;
    padding: 0px 5px;
    border-radius: $border-radius;
}
.label:empty {
    display: none;
}

/* Hasnotification */
.hasnotification {
    display: inline-block;
    width: 8px;
    height: 8px;
    text-indent: -999999px;
    border-radius: 50%;
    background-color: $gray;
}

/* Color */
.label-default{
    background-color: $default;
    color: darken($default, 30%);
}
.label-primary,
.hasnotification-primary {
    background-color: $primary;
}
.label-success,
.hasnotification-success {
    background-color: $success;
}
.label-info,
.hasnotification-info {
    background-color: $info;
}
.label-warning,
.hasnotification-warning {
    background-color: $warning;
}
.label-danger,
.hasnotification-danger {
    background-color: $danger;
}
.label-teal,
.hasnotification-teal {
    background-color: teal;
}
.label-inverse,
.hasnotification-inverse {
    background-color: $inverse;
    color: $white;
}