/* ----------------------------
 *
 * To Top Scroller
 *
 * --------------------------- */
.totop {
    position: fixed;
    z-index: 998;
    bottom: 10px;
    right: 25px; //Avoid scrollbars on Laptops
    display: block;
    width: 80px;
    height: 40px;
    line-height: 40px;
    background-color: rgba(0,0,0,0.4);
    color: $white;
    opacity: 0.8;
    text-align: center;
    text-shadow: 0px -1px 0px rgba(0, 0, 0, 0.1);
    font-size: 16px;

    &.pull-left {
        right: auto;
        left: 10px;
    }

    &:hover,
    &:active,
    &:focus {
        color: $white;
        background-color: rgba(0,0,0,0.6);
        opacity: 0.9;
        outline: 0;
    }
}