/* ----------------------------
 *
 * card
 *
 * --------------------------- */
.card {
    /* max-width: 100%;  required by text ellipsis */
    margin-bottom: 20px;
    position: relative;
    border-width: 1px;
    border-color: $border-color;
    border-bottom-width: 2px;
    border-bottom-color: darken($border-color, 5%);
    border-radius: $border-radius;
    -webkit-box-shadow: none;
    box-shadow: none;
    background-color: #fff;
}
.card > .card-collapse.pull {
    display: none;
}
.card > .card-collapse.pull.out {
    position: relative;
    display: block;
}
.card > .card-collapse.pulling {
    position: relative;
    overflow: hidden;
}

/* card widget
 -------------------------------*/
.widget {
    margin-bottom: 20px;
}
.widget .card,
.widget.card {
    border-bottom-width: 1px;
    border-bottom-color: $border-color;
    overflow: hidden;
}

/* card ribbon
 *   - contextual
 -------------------------------*/
.card > .card-ribbon {
    position: absolute;
    z-index: 10;
    overflow: hidden;
    top: -1px;
    left: -1px;
    width: 40px;
    height: 40px;
    border-top-left-radius: $border-radius;
    color: darken($gray, 40%);
    text-decoration: none;
}
.card > .card-ribbon.pull-right {
    left: auto;
    right: -1px;
    border-top-right-radius: $border-radius;
    text-align: right;
}
.card > .card-ribbon > [class*=" ico-"],
.card > .card-ribbon > [class^="ico-"] {
    display: inline-block;
    position: relative;
    width: 13px;
    line-height: 13px;
    margin-top: 6px;
    margin-left: 6px;
    text-align: center;
    z-index: 1;
}
.card > .card-ribbon.pull-right > [class*=" ico-"],
.card > .card-ribbon.pull-right > [class^="ico-"] {
    margin-right: 6px;
    margin-left: 0px;
}
.card > .card-ribbon.pull-right:after {
    border-width: 0 40px 40px 0;
    border-color: transparent $gray transparent transparent;
}
.card > .card-ribbon:after {
    position: absolute;
    top: 0px;
    left: 0px;
    content: "";
    width: 0px;
    height: 0px;
    border-style: solid;
    border-width: 40px 40px 0 0;
    border-color: $gray transparent transparent transparent;
}
/*
 * contextual
 */
/* primary */
.card > .card-ribbon-primary { color: darken($primary, 15%); }
.card > .card-ribbon-primary:after { border-color: $primary transparent transparent transparent; }
.card > .card-ribbon-primary.pull-right:after { border-color: transparent $primary transparent transparent; }
/* success */
.card > .card-ribbon-success { color: darken($success, 15%); }
.card > .card-ribbon-success:after { border-color: $success transparent transparent transparent; }
.card > .card-ribbon-success.pull-right:after { border-color: transparent $success transparent transparent; }
/* warning */
.card > .card-ribbon-warning { color: darken($warning, 15%); }
.card > .card-ribbon-warning:after { border-color: $warning transparent transparent transparent; }
.card > .card-ribbon-warning.pull-right:after { border-color: transparent $warning transparent transparent; }
/* info */
.card > .card-ribbon-info { color: darken($info, 15%); }
.card > .card-ribbon-info:after { border-color: $info transparent transparent transparent; }
.card > .card-ribbon-info.pull-right:after { border-color: transparent $info transparent transparent; }
/* danger */
.card > .card-ribbon-danger { color: darken($danger, 15%); }
.card > .card-ribbon-danger:after { border-color: $danger transparent transparent transparent; }
.card > .card-ribbon-danger.pull-right:after { border-color: transparent $danger transparent transparent; }
/* inverse */
.card > .card-ribbon-inverse { color: darken($inverse, 15%); }
.card > .card-ribbon-inverse:after { border-color: $inverse transparent transparent transparent; }
.card > .card-ribbon-inverse.pull-right:after { border-color: transparent $inverse transparent transparent; }
/* teal */
.card > .card-ribbon-teal { color: darken(teal, 15%); }
.card > .card-ribbon-teal:after { border-color: teal transparent transparent transparent; }
.card > .card-ribbon-teal.pull-right:after { border-color: transparent teal transparent transparent; }

.card > .card-ribbon-secondary { color: darken($secondary, 15%); }
.card > .card-ribbon-secondary:after { border-color: $secondary transparent transparent transparent; }
.card > .card-ribbon-secondary.pull-right:after { border-color: transparent $secondary transparent transparent; }

/* card heading
 *   - card icon
 *   - contextual
 *   - reset
 -------------------------------*/
.card-header {
    padding: 0 15px;
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;

    /* experiment */
    margin-left: -1px;
    margin-right: -1px;
    margin-top: -1px;
    border: 1px solid transparent;
}
.card-header > .card-title,
.card-header > .card-toolbar {
    display: table-cell;
    vertical-align: middle;
    width: 1%;
    height: 40px;
    float: none !important;
}
.card-header > .card-title.ellipsis,
.card-header > .card-toolbar.ellipsis {
    max-width: 10px;
}
.card-header > .card-title {
    font-size: 14px;
    font-weight: 600;
}
.card-header > .card-title > .icon {
    margin-right: 5px;
}
.card-header > .card-title > a,
.card-header > .card-title > a:hover,
.card-header > .card-title > a:active,
.card-header > .card-title > a:focus {
    text-decoration: none;
    outline: 0;
}
.card-header > .card .card-header + .card-body {
    border-bottom-right-radius: 0px;
    border-bottom-left-radius: 0px;
}

/* card Toolbar
 *   static text
 *   button link
 *   option
 *   input field
 *   tabs
 -------------------------------*/
.card-toolbar-wrapper {
    display: block;
    background-color: lighten($gray, 5%);
    border-bottom: 1px solid $border-color;
    padding: 0 15px;
}
.card-toolbar-wrapper.bottom {
    border-bottom-width: 0px;
    border-top: 1px solid $border-color;
}
.card-toolbar-wrapper > .card-toolbar {
    display: table-cell;
    vertical-align: middle;
    width: 1%;
    height: 40px;
    float: none !important;
}
.card-toolbar-wrapper > .card-toolbar.ellipsis {
    max-width: 10px;
}
.card .card-footer > .card-toolbar-wrapper {
    background-color: transparent;
    padding: 0;
    border-width: 0;
}
/*
 * static text
 */
.card .card-toolbar .static-text {
    display: inline-block;
    vertical-align: middle;
    line-height: 34px;
    color: lighten($dark, 20%);
}
/*
 * btn-link
 */
.card .card-toolbar .btn-link {
    color: lighten($gray, 2%);
    text-decoration: none;
}
.card .card-toolbar .btn-link:hover,
.card .card-toolbar .btn-link:focus,
.card .card-toolbar .btn-link:active {
    color: $white;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.card.card-default .card-toolbar .btn-link {
    color: $primary;
}
.card.card-default .card-toolbar .btn-link:hover,
.card.card-default .card-toolbar .btn-link:active,
.card.card-default .card-toolbar .btn-link:focus {
    color: darken($primary, 5%);
}
/*
 * option
 */
.card .card-toolbar > .option {
    display: inline-block;
    float: right;
    min-height: 34px;
}
.card .card-toolbar > .option > .btn {
    float: left;
    background-color: transparent;
    color: lighten($gray, 2%);
    padding-left: 6px;
    padding-right: 6px;
}
.card.card-default .card-toolbar > .option > .btn {
    color: lighten($dark, 20%);
}
.card .card-toolbar > .option > .btn:hover,
.card .card-toolbar > .option > .btn:active,
.card .card-toolbar > .option > .btn:focus {
    color: $white;
    outline: 0;
    box-shadow: none;
    -webkit-box-shadow: none;
}
.card.card-default .card-toolbar > .option > .btn:hover,
.card.card-default .card-toolbar > .option > .btn:active,
.card.card-default .card-toolbar > .option > .btn:focus {
    color: lighten($dark, 10%);
}
/* icon */
.card .card-toolbar > .option > .btn .arrow,
.card .card-toolbar > .option > .btn .reload,
.card .card-toolbar > .option > .btn .remove {
    display: block;
    font-family: 'iconfont';
    font-size: 12px;
    width: 12px;
    text-align: center;
    font-style: normal;
}
.card .card-toolbar > .option > .btn.up > .arrow:before {
    content: "\e670";
}
.card .card-toolbar > .option > .btn > .arrow:before {
    content: "\e671";
}
.card .card-toolbar > .option > .btn > .reload:before {
    content: "\e61d";
    font-size: 13px;
}
.card .card-toolbar > .option > .btn > .remove:before {
    content: "\e36c";
}
/*
 * input field
 */
//.card .card-toolbar > .form-horizontal .mb-3 {
//    margin: 0px;
//}
.card .card-toolbar > .form-horizontal .has-feedback .form-control-feedback {
    right: 0px;
}
/*
 * tabs
 */
.card .card-toolbar > .nav-tabs {
    border-bottom: 0px;
    margin-bottom: -4px;
    background-color: transparent;
}
.card .card-toolbar > .nav-tabs > li > a {
    padding: 8px 15px;
    line-height: 20px;
    border: 1px solid transparent;
    border-radius: $border-radius $border-radius 0px 0px;
    color: lighten($gray, 2%);
}
.card .card-toolbar > .nav-tabs > li.active > a,
.card .card-toolbar > .nav-tabs > li.active > a:hover,
.card .card-toolbar > .nav-tabs > li.active > a:active,
.card .card-toolbar > .nav-tabs > li.active > a:focus {
    border-color: $border-color;
    border-bottom-color: transparent;
    background-color: $white;
    color: $dark;
}
.card .card-toolbar > .nav-tabs > li.active > a:before {
    display: none;
}
/* card default reset */
.card-default .card-toolbar > .nav-tabs > li > a:hover,
.card-default .card-toolbar > .nav-tabs > li.open > a {
    border-bottom-color: $border-color;
    color: lighten($dark, 20%);
}
.card-default .card-toolbar > .nav-tabs > li > a {
    color: lighten($dark, 40%);
}
/* tab inside card toolbar wrapper */
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li > a {
    color: lighten($dark, 40%);
}
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li > a:hover,
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li.open > a,
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li.active > a,
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li.active > a:hover,
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li.active > a:active,
.card .card-toolbar-wrapper > .card-toolbar > .nav-tabs > li.active > a:focus {
    color: lighten($dark, 20%);
}

/* card body
 *   - indicator
 *   - scrollable
 *   - info
 *   - background
 *   - iframe
 *   - contextual
 -------------------------------*/
.card-body {
    position: relative;
}
.card-body:last-child {
    border-bottom: 0px;
    padding: 15px;
}
.card-body.no-contextual {
    background-color: inherit !important;
    color: inherit !important;
}
.card .card-body + .table-responsive {
    border-top: 1px solid $border-color;
}
.card > .card-body + .table,
.card > .card-body + .table-responsive {
    border-color: $border-color;
}
/*
 * indicator
 */
.card-body > .indicator {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
}
/*
 * scrollable
 */
.card .viewport:after {
    pointer-events: none;
    position: absolute;
    content: "";
    z-index: 10;
    left: 0px;
    right: 0px;
    top: 0px;
    height: 40px;

    background-image: linear-gradient(to bottom,  $white 0%, $white 15%, fade($white, 0%) 100%);
}
.card .viewport:before {
    pointer-events: none;
    position: absolute;
    content: "";
    z-index: 10;
    left: 0px;
    right: 0px;
    bottom: 0px;
    height: 40px;

    background-image: linear-gradient(to bottom,  fade($white, 0%) 0%, $white 85%, $white 100%);
}
.touch .card-body.slimscroll {
    overflow-x: hidden;
    overflow-y: scroll;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    -webkit-overflow-scrolling: touch;
}

/* card footer
 -------------------------------*/
.card-footer {
    overflow: auto;
    background-color: lighten($gray, 4%);
    border-top: 1px solid $border-color;
    border-bottom-right-radius: $border-radius;


}
.card-body .card-footer {
    margin: 15px -15px -15px -15px;
}
.card-footer + .card-body {
    border-top: 1px solid $border-color;
}

/* card group
 *    - arrow
 *    - plus
 -------------------------------*/
.card-group .card {
    border-radius: $border-radius + 1;
}
.card-group.card-group-compact .card,
.card-group.card-group-compact .card .card-header {
    border-bottom: none;
    border-radius: 0px;
}
.card-group.card-group-compact .card:first-child,
.card-group.card-group-compact .card:first-child .card-header {
    border-top-right-radius: $border-radius;
    border-top-left-radius: $border-radius;
}
.card-group.card-group-compact .card:last-child {
    border-bottom: 1px solid $border-color;
    border-bottom-right-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}
.card-group.card-group-compact .card + .card {
    margin: 0px;
}
/*
 * arrow
 */
.card-group .card-title > a > .arrow,
.card-group .card-title > a > .plus {
    text-align: left;
    font-family: "iconfont";
    font-weight: normal;
    font-size: 12px;
    line-height: 12px;
    width: 12px;
}
.card-group .card-title > a > .arrow:before {
    content: "\e670";
}
.card-group .card-title > a.collapsed > .arrow:before {
    content: "\e671";
}
.card-group .card-title > a > .plus:before {
    content: "\e662";
}
.card-group .card-title > a.collapsed > .plus:before {
    content: "\e661";
}

/* card Header contextual
 *   - default
 *   - primary
 *   - success
 *   - info
 *   - warning
 *   - danger
 *   - inverse
 *   - teal
 *   - reset
 -------------------------------*/
/*
 * default
 */
.card-default > .card-header {
    color: $dark;
    background-color: lighten($gray, 3%);
    border-color: $border-color;
}
/*
 * primary
 */
.card-primary > .card-header {
    color: $white;

    background-color: $primary;
    border-color: darken($primary, 3%);
}
.card-primary > .card-header + .card-collapse .card-body {
    border-top-color: darken($primary, 5%);
}
/*
 * success
 */
.card-success > .card-header {
    color: $white;
    background-color: $success;
    border-color: darken($success, 5%);
}
/*
 * info
 */
.card-info > .card-header {
    color: $white;
    background-color: $info;
    border-color: darken($info, 6%);
}
/*
 * warning
 */
.card-warning > .card-header {
    color: $white;
    background-color: $warning;
    border-color: darken($warning, 6%);
}
/*
 * danger
 */
.card-danger > .card-header {
    color: $white;
    background-color: $danger;
    border-color: darken($danger, 6%);
}
/*
 * inverse
 */
.card-inverse > .card-header {
    color: $white;
    background-color: $inverse;
    border-color: darken($inverse, 6%);
}
/*
 * teal
 */
.card-teal > .card-header {
    color: $white;
    background-color: teal;
    border-color: darken(teal, 5%);
}

.card-secondary > .card-header {
    color: $white;

    background-color: $secondary;
    border-color: darken($secondary, 5%);
}
.card-secondary > .card-header + .card-collapse .card-body {
    border-top-color: darken($secondary, 5%);
}


.card-dark > .card-header {
    color: $white;

    background-color: $dark;
    border-color: darken($dark, 5%);
}
.card-dark > .card-header + .card-collapse .card-body {
    border-top-color: darken($dark, 5%);
}

/* card Minimal
 -------------------------------*/
.card.card-minimal {
    border-width: 0px;
    border-radius: 0px;
    background-color: transparent;
}
.card.card-minimal > .card-header {
    border-width: 0px;
    background-color: transparent !important;
}
.card.card-minimal > .card-toolbar-wrapper {
    background-color: transparent;
    border-color: transparent;
}
.card.card-minimal .card-footer {
    background-color: transparent;
    border-width: 0px;
}

/* card Table layout
 -------------------------------*/
.table-layout > [class*=" col-"].card,
.table-layout > [class^="col-"].card {
    /*margin-bottom: 0px;
    overflow: hidden;*/
}
.table-layout > [class*=" col-"].card-minimal:first-child + [class*=" col-"].card,
.table-layout > [class^="col-"].card-minimal:first-child + [class^="col-"].card {
    border-left-width: 1px;
}
.table-layout > [class*=" col-"].card-minimal:first-child + [class*=" col-"].card.card-minimal,
.table-layout > [class^="col-"].card-minimal:first-child + [class^="col-"].card.card-minimal {
    border-left-width: 0px;
}
.table-layout > [class*=" col-xs"].card,
.table-layout > [class^="col-xs"].card {
    border-radius: 0px;
}
.table-layout > [class*=" col-xs"].card + [class*=" col-xs"].card,
.table-layout > [class^="col-xs"].card + [class^="col-xs"].card {
    border-left-width: 0px;
}
.table-layout > [class*=" col-xs"].card:first-child,
.table-layout > [class^="col-xs"].card:first-child {
    border-top-left-radius: $border-radius;
    border-bottom-left-radius: $border-radius;
}
.table-layout > [class*=" col-xs"].card:last-child,
.table-layout > [class^="col-xs"].card:last-child {
    border-top-right-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
}
@media (min-width: $screen-sm-min) {
    .table-layout > [class*=" col-sm"].card,
    .table-layout > [class^="col-sm"].card {
        border-radius: 0px;
    }
    .table-layout > [class*=" col-sm"].card + [class*=" col-sm"].card,
    .table-layout > [class^="col-sm"].card + [class^="col-sm"].card {
        border-left-width: 0px;
    }
    .table-layout > [class*=" col-sm"].card:first-child,
    .table-layout > [class^="col-sm"].card:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    .table-layout > [class*=" col-sm"].card:last-child,
    .table-layout > [class^="col-sm"].card:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
@media (min-width: $screen-md-min) {
    .table-layout > [class*=" col-md"].card,
    .table-layout > [class^="col-md"].card {
        border-radius: 0px;
    }
    .table-layout > [class*=" col-md"].card + [class*=" col-md"].card,
    .table-layout > [class^="col-md"].card + [class^="col-md"].card {
        border-left-width: 0px;
    }
    .table-layout > [class*=" col-md"].card:first-child,
    .table-layout > [class^="col-md"].card:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    .table-layout > [class*=" col-md"].card:last-child,
    .table-layout > [class^="col-md"].card:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}
@media (min-width: $screen-lg-min) {
    .table-layout > [class*=" col-lg"].card,
    .table-layout > [class^="col-lg"].card {
        border-radius: 0px;
    }
    .table-layout > [class*=" col-lg"].card + [class*=" col-lg"].card,
    .table-layout > [class^="col-lg"].card + [class^="col-lg"].card {
        border-left-width: 0px;
    }
    .table-layout > [class*=" col-lg"].card:first-child,
    .table-layout > [class^="col-lg"].card:first-child {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
    }
    .table-layout > [class*=" col-lg"].card:last-child,
    .table-layout > [class^="col-lg"].card:last-child {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
    }
}

/* card Thumbnail
 -------------------------------*/
.card > .thumbnail,
.card .card-figure > .thumbnail {
    border-radius: 0px;
    border-width: 0px;
    margin: 0px;
}
.card > .thumbnail > .media > .overlay,
.card > .thumbnail > .media > img,
.card .card-figure > .thumbnail > .media > .overlay,
.card .card-figure > .thumbnail > .media > img {
    border-radius: 0px;
}
.card > .thumbnail > .caption,
.card > .thumbnail > .meta,
.card .card-figure > .thumbnaill > .caption,
.card .card-figure > .thumbnaill > .meta {
    padding: 9px 15px;
}